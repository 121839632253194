/// <reference path="app.ts" />
interface iForm {
  form: HTMLFormElement;
  url: string;
  dataArray: any;
  form_type: string;
  validation(): boolean;
  ajax(): void;
}

class Form implements iForm {
  form: HTMLFormElement;
  url:string;
  dataArray: any;
  form_type: string;
  constructor (form:HTMLFormElement, url:string, dataArray:any, form_type:string) {
    this.form = form;
    this.url = url;
    this.dataArray = dataArray;
    this.form_type = form_type;
  }

  validation() : boolean {
    let valid:boolean = true;

    $.each($(this.form).find('.required'), function(i, val) {
      if($(this).val() == "") {
        valid = false; 
        $(this).addClass('error');
      } else {
        $(this).removeClass('error'); 
      }
    });

    if($(this.form).find('[name="email"]').val() != "" && $(this.form).find('[name="email"]').val() != undefined) {
      let email = $(this.form).find('[name="email"]');
      let pattern = /^[\.a-zA-Z0-9_-]+@[a-zA-Z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
      
      if( email.val().search(pattern) == 0 ) {
        email.removeClass('error');
      } else {
        valid = false; 
        email.addClass('error');
      }
    }


    if(this.form_type == "new_order") {
      if(this.form.delivery_outpost.value == "" && this.form.delivery.value == "3" ) {
        valid = false;
        $('#map_menu .radio').addClass('error');
      } else {
        $('#map_menu .radio').removeClass('error');
      }
    }
    

    return valid;
  }

  ajax(): void {

    let valid:boolean = this.validation();
    if(valid) {
      let success_type = this.form_type;
      let data = this.dataArray;
      let url = this.url;
      $.ajax({
        type: "POST",
        url: url,
        processData: false,
        contentType: false,
        data: data
      }).done(function(res) {
        //console.log(res);
        //if(res) {

          if(success_type == "new_order") {
            let arr = JSON.parse(res);

            if( $('#payment_type').val() != 4 ) {
              if(arr['order_id'] != "" && arr['is_authorized'] == true) {
                document.location.href = arr['redirect'];
              } else {
                alert('Что-то пошло не так, повторите запрос позднее!');
              }
            } else {
              $('#yandex_customer').val(arr['user_id']);
              $('#yandex_sum').val(arr['total']);
              $('#yandex_order').val(arr['order_id']);
              $('#yandex_success').val(arr['redirect']);
              $('#yandex_fail').val(arr['yandex_fail']);
              $('form#yandex').submit();
            }            
          } else {
            showSuccess(success_type);   

            if(success_type == "addtocart") {
              var object = {};
              data.forEach(function(value, key){
                  object[key] = value;
              });
              countUpdate(0, object['id']);
            }
          }

        //}          
      }).fail(function(res) {
        //console.log(res);
       });
    }
  }
}


$(function() {
    $('form.kult_sna').submit(function(e) {
        e.preventDefault(); 
      
        let dataArray = new FormData(this);
        dataArray.append('type', $(this).attr('name'));
        let form_type = $(this).attr('name');
        let url = $(this).attr('action');
        let form:Form = new Form(this, url, dataArray, form_type);
        let response = form.ajax();
    });
});      